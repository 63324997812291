<template>
  <section class="panorama-info p-0">

    <div id="panoramaWrapper" class="full-height"></div>

    <div v-if="!sensor" class="poster">
      <div class="gyro-button" @click="enableSensor()">
        <d-icon
          pack="far"
          icon="360-degrees"
          size="is-medium"/>
       </div>
    </div>
  
    
  </section>
</template>

<script>
import './PanoramaInfo.scss'
import { PanoViewer } from "@egjs/view360";

export default {
  components: {},
  props: {
    info: {
      type: Object,
      required: true,
    },
    isOnComputer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      panoViewer: undefined,
      sensor: false,
      vr: false,
      mode: 'none',
      projectionType: 'equirectangular'
    }
  },
  computed: {},
  methods: {
    setGyroMode(mode) {
      this.mode = mode;
      console.log('setting ' +mode,this.panoViewer.setGyroMode(mode)) 
    },
    enableSensor(){
        this.setGyroMode('yawPitch')
        this.panoViewer.enableSensor()
        this.sensor = true
    },
  },
  mounted() {
    console.log(this.info.image.url)

    this.panoViewer = new PanoViewer(
      document.getElementById("panoramaWrapper"),
      {
        image: this.info.image.url,
        projectionType: this.projectionType,
        gyroMode: this.mode
      }
    );

  },
};
</script>